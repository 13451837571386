<template>
    <Modal v-model="showForm" title="查看幻灯" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="90">
            <FormItem label="幻灯名称">{{ viewForm.name }}</FormItem>
            <FormItem label="幻灯">
                <div class="upload-image-list">
                    <img :src="imgData">
                    <div class="upload-image-list-cover">
                        <Icon type="ios-eye" @click.native="handlePreview(imgData)"></Icon>
                    </div>
                </div>
            </FormItem>
            <FormItem label="企业名称">{{viewForm.customerName}}</FormItem>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
            <img :src="imgData" style="width: 100%" />
        </Modal>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqGetSlideShowById} from "@/api/admin-api";
import {SERVER_HOST} from "@/common/portConfig";

export default {
    name: "viewWcSlideshow",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            showPreview: false,
            imgData: null,
            loading: false,
            showForm: this.value,
            deleteName: [],
            viewForm: {},
            viewFormRule: {},
        }
    },
    methods: {
        getSlideShowById() {
            reqGetSlideShowById({id: this.id}).then((res)=>{
                if (res.data.code ===1){
                    this.viewForm = res.data.data
                    this.imgData = SERVER_HOST + '/resource/public/' + res.data.data.currentName
                }else {
                    this.editForm = {}
                }
            }).catch(()=>{
                this.editForm = {}
            })
        },
        handlePreview(val) {
            this.showPreview = true;
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.imgData = null
                this.getSlideShowById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
