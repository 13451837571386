<template>
    <Modal v-model="showForm" title="新增幻灯" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="80">
            <FormItem label="幻灯名称" prop="name">
                <Input type="text" :maxlength="40" v-model="addForm.name" placeholder="请输入幻灯名称"/>
            </FormItem>
            <FormItem label="幻灯图片" prop="originalPictureName">
                <Upload ref="upload" type="drag"
                        :headers="header"
                        :max-size="5 * 1024"
                        :action="uploadFiles"
                        :data="{type: 'public'}"
                        :show-upload-list="false"
                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                        accept=".jpg,.png,.JPG,.JPEG"
                        :on-exceeded-size="(file) => handExceeded(file, 1)"
                        :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'photoImgArray')"
                        v-if="photoImgArray.length < 1">
                    <div class="upload-btn-l">
                      <Icon type="md-cloud-upload"></Icon>
                      <p class="tips">要求：支持.jpg，.png，.JPG，.JPEG格式，5M以内</p>
                    </div>
                </Upload>
                <div class="upload-image-list" v-for="(item, index) in photoImgArray">
                    <img :src="item.imgData"  style="width: 100%"/>
                    <div class="upload-image-list-cover">
                      <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                      <Icon type="ios-trash error" @click.native="handleRemove('photoImgArray', index)"></Icon>
                    </div>
                </div>
            </FormItem>
            <FormItem label="企业名称" prop="customerId">
                <Select v-model="addForm.customerId" filterable clearable>
                    <Option v-for="item in customerArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
          <img :src="imgView" v-if="showPreview" style="width: 100%"/>
        </Modal>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {uploadFileURL, reqDeleteFiles} from "@/api/system-api";
import {reqAddSlideShow} from "@/api/admin-api";
import {SERVER_HOST} from "@/common/portConfig";
import {reqGetCustomerArray} from "../../../api/admin-api";

export default {
    name: "addWcSlideshow",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            header: {},
            showPreview: false,
            imgView: null,
            fileCounts: 0,
            uploadFiles: uploadFileURL,
            loading: false,
            showForm: this.value,
            photoImgArray: [],
            customerArray: [],
            addForm: {
                name: '',
                originalPictureName: null,
                currentPictureName: null,
            },
            addFormRule: {
                name: [{required: true, message: '请输入幻灯名称', trigger: 'blur'}],
                originalPictureName: [
                  {required: true, message: "请上传幻灯图片", trigger: 'blur'}
                ]
            },
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['addForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                reqAddSlideShow(this.addForm).then((res)=>{
                    this.loading = false;
                    if (res.data.code === 1){
                        this.$Message.success('添加成功');
                        this.$emit('getSlideShow');
                        this.closeModal(false);
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('添加失败');
                })
            });
        },
        handleBeforeUpload(file, maxNum) {
          this.fileCounts++;
          if (this.fileCounts > maxNum) {
            this.$Message.error('最多只能上传' + maxNum + '个文件');
            return false;
          }else {
            this.addForm.fileArray.push(file)
          }
        },
        //上传文件大小
        handExceeded(file, maxSize) {
          this.$Message.error('文件大小不得超过' + maxSize + 'M');
        },
        //上传成功
        handleSuccess(res, file, fileList, propName) {
            this.fileCounts = 0;
            if (res.code === 1) {
              this.addForm.originalPictureName = res.data.fileName;
              this.addForm.currentPictureName = res.data.currentName;
              this.photoImgArray.push({imgName: res.data.currentName, imgData: SERVER_HOST + '/resource/public/' + res.data.currentName})
              this.$Message.success('上传成功');
            } else {
              this.$Message.error('上传失败');
            }
        },
        handlePreview(imgData) {
            this.imgView = imgData;
            this.showPreview = true;
        },
        handleRemove(propName, index) {
            this.addForm.originalPictureName = null;
            this.addForm.currentPictureName = null;
            reqDeleteFiles([{fileName: this[propName][index].imgName, type: 'public'}]).then((res) => {
              if (res.data.data && res.data.data.length > 0 && res.data.data[0].result) {
                this[propName].splice(index, 1);
              } else {
                this.$Message.error('删除失败');
              }
            })
        },
        getCustomerArray() {
            reqGetCustomerArray().then((res)=>{
                this.customerArray = res.data.data
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.photoImgArray = []
                this.getCustomerArray()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
    }
}
</script>
